import { AxiosResponse } from 'axios'
import axi from './axios'

export type ServiceCategory = {
  id: number
  title: string
}

export type ServiceCategories = Array<ServiceCategory>

export const getCategories = async () => {
  const data: AxiosResponse<ServiceCategories> = await axi.get('/categories')
  return data
}

type Unit = {
  id: number
  title: string
}

type Category = {
  id: number
  title: string
}

export type Service = {
  id: number
  title: string
  description: string | undefined
  comment: string | undefined
  quantity: number | undefined
  unit: Unit | undefined
  available_start_at: string
  available_end_at: string
  price: number
  category_id: number
  category: Category
}

export type Services = Array<Service>

export const getServices = async () => {
  const data: AxiosResponse<Services> = await axi.get('/services')
  return data
}

export type CheckoutData = {
  id: string
  payment_url: string
  services: string
}

export const checkoutService = async (service_ids: Array<number>) => {
  const data: AxiosResponse<CheckoutData> = await axi.post('/checkout', {
    service_ids: service_ids,
  })
  console.log(data.status)
  return data
}
