import React from 'react'
import { cn } from '../../utils/cn'
import { Button } from './Button'
//@ts-ignore
import surguLogo from 'data-url:../../../resources/surgu-logo.svg'
import { useServiceStore } from '../../store/service'

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

const SurguLogo = React.forwardRef<HTMLButtonElement, ButtonProps>(({ className, ...props }, ref) => {
	const serviceState = useServiceStore(state => state.service)
	const setServiceState = useServiceStore(state => state.setService)

	const handleClick = () => {
		if (serviceState) setServiceState(undefined)
	}

	return (
		<Button variant={'ghost'} onClick={handleClick} className={cn('text-text-secondary hover:bg-transparent', className)} {...props}>
			<img src={surguLogo} alt='СурГУ' className='w-40 mx-auto' />
		</Button>
	)
})
export default SurguLogo
