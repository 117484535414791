import { AxiosResponse } from 'axios'
import { convertDate } from '../utils/format'
import axi from './axios'

/** 
	Example:
  "phone": "79411234546",
  "email": "xxx@mail.ru",
  "first_name": "Xxx",
  "last_name": "Xxx",
  "patronymic": "Xxx",
  "passport_number": "1234 567890",
  "passport_date": "2024-12-12",
  "birthday": "2024-12-12"
*/
export type User = {
	first_name: string | null
	last_name: string | null
	patronymic: string | null
	passport_number: string | null
	passport_date: string | null
	phone: string
	email: string | null
	birthday: string | null
}

export const getUser = async () => {
	const data: AxiosResponse<User> = await axi.get('/user')
	if (data.data.birthday) data.data.birthday = convertDate(data.data.birthday)
	if (data.data.passport_date) data.data.passport_date = convertDate(data.data.passport_date)
	return data
}

export type SaveUserData = { message: string }
export const saveUser = async (user: User) => {
	if (user.birthday) user.birthday = convertDate(user.birthday)
	if (user.passport_date) user.passport_date = convertDate(user.passport_date)
	const data: AxiosResponse<SaveUserData> = await axi.post('/user', user)
	return data
}
