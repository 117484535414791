import { useMutation } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import jcb from 'data-url:../../resources/payment/jcb-logo.svg'
import mastercard from 'data-url:../../resources/payment/mastercard-logo.svg'
import mir from 'data-url:../../resources/payment/mir-logo.svg'
import visa from 'data-url:../../resources/payment/visa-logo.svg'
import { useState } from 'react'
import { CheckoutData, checkoutService } from '../api/service'
import { saveUser } from '../api/user'
import ServiceCard from '../components/ServiceCard'
import UserCard from '../components/UserCard'
import UserForm, { UserFormData } from '../components/UserForm'
import { Button } from '../components/ui/Button'
import { useServiceStore } from '../store/service'
import { useUserStore } from '../store/user'
import { cn } from '../utils/cn'
import { formatPrice } from '../utils/format'

const Payment = () => {
  const paymentMutation = useMutation({
    mutationFn: checkoutService,
    onSuccess: (data: AxiosResponse<CheckoutData>) => {
      window.location.assign(data.data.payment_url)
    },
  })

  const setUserState = useUserStore((state) => state.setUser)
  const userState = useUserStore((state) => state.user)
  const setServiceState = useServiceStore((state) => state.setService)
  const serviceState = useServiceStore((state) => state.service)

  const [editMode, setEditMode] = useState(false)

  const userMutation = useMutation({
    mutationFn: saveUser,
  })

  if (!userState?.phone) return <span className='text-text-destructive'>Bug: no phone number in user state</span>
  if (!serviceState?.id) return <span className='text-text-destructive'>Bug: no service in service state</span>

  const onSubmit = (data: UserFormData) => {
    setUserState({ ...data, phone: userState.phone })
    data.birthday = data.birthday.split('.').reverse().join('-')
    data.passport_date = data.passport_date.split('.').reverse().join('-')
    userMutation.mutate({ ...data, phone: userState.phone })
    setEditMode(false)
  }

  const paymentAction = () => paymentMutation.mutate([serviceState.id])

  return (
    <div className='flex flex-col w-full gap-6'>
      <div className='flex flex-col items-start gap-3'>
        <h1 className='text-xl font-medium'>Оформление услуги</h1>
        {serviceState && <ServiceCard onClick={() => setServiceState(undefined)} service={serviceState} />}
        {userState &&
          (!userState.passport_number || editMode ? (
            <UserForm onSubmit={onSubmit} />
          ) : (
            <>
              <UserCard editAction={() => setEditMode(true)} />{' '}
              <div className='flex flex-col w-full gap-6'>
                <div className='flex flex-col w-full gap-4'>
                  <div className='flex flex-row items-end justify-between w-full'>
                    <span className='text-sm font-medium'>Стоимость услуг</span>
                    <span className='text-2xl text-text-accent normal-nums'>{serviceState?.price && formatPrice(serviceState?.price) + ' ₽'}</span>
                  </div>
                  <Button
                    className={cn(paymentMutation.isPending && 'hover:cursor-wait')}
                    disabled={paymentMutation.isPending}
                    variant={'accent'}
                    onClick={paymentAction}
                  >
                    Оплатить картой
                  </Button>
                </div>
                <p className='text-xs text-text-tertiary'>
                  Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены на платёжный шлюз ПАО СБЕРБАНК. Соединение с платёжным шлюзом и передача
                  информации осуществляется в защищённом режиме с использованием протокола шифрования SSL. В случае если Ваш банк поддерживает технологию
                  безопасного проведения интернет-платежей Verified By Visa, MasterCard SecureCode, MIR Accept, J-Secure, для проведения платежа также может
                  потребоваться ввод специального пароля. Настоящий сайт поддерживает 256-битное шифрование. Конфиденциальность сообщаемой персональной
                  информации обеспечивается ПАО СБЕРБАНК. Введённая информация не будет предоставлена третьим лицам за исключением случаев, предусмотренных
                  законодательством РФ. Проведение платежей по банковским картам осуществляется в строгом соответствии с требованиями платёжных систем МИР, Visa
                  Int., MasterCard Europe Sprl, JCB.
                </p>
                <div className='flex flex-row gap-3 *:size-10 mx-auto *:opacity-80 select-none'>
                  <img src={jcb} alt='JCB' />
                  <img src={visa} alt='VISA' />
                  <img src={mir} alt='MIR' />
                  <img src={mastercard} alt='MasterCard' />
                </div>
              </div>
            </>
          ))}
      </div>
    </div>
  )
}

export default Payment
