const ServicesSkeleton = () => {
	return (
		<div className='flex flex-col justify-center w-full gap-6'>
			<div className='flex flex-col justify-center gap-3'>
				<div className='rounded flex w-full h-[42px] animate-pulse animate-infinite bg-skeleton' />
				<div className='flex flex-wrap gap-2'>
					{Array.from({ length: 5 }, (_, i) => (
						<div key={i} className={`min-w-20 h-6 animate-pulse flex justify-center items-center animate-infinite rounded-[20px] bg-skeleton text-sm`}></div>
					))}
				</div>
			</div>
			<div className='flex flex-col justify-center gap-3'>
				{Array.from({ length: 5 }, (_, i) => (
					<div key={i} className='flex w-full h-20 rounded bg-skeleton animate-pulse animate-infinite' />
				))}
			</div>
		</div>
	)
}

export default ServicesSkeleton
