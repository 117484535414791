import { REGEXP_ONLY_DIGITS } from 'input-otp'
import { Search } from 'lucide-react'
import React from 'react'
import PaymentResult from '../components/PaymentResult'
import ServiceCard from '../components/ServiceCard'
import UserCard from '../components/UserCard'
import { Button } from '../components/ui/Button'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from '../components/ui/Dropdown'
import { Input } from '../components/ui/Input'
import { InputOTP, InputOTPGroup, InputOTPSlot } from '../components/ui/InputOTP'
import Modal from '../components/ui/Modal'
import { formatPhoneNumber } from '../utils/format'
import Flow from './Flow'
import OTP from './OTP'
import Payment from './Payment'
import Auth from './Phone'
import Main from './Services'

const TestUI = () => {
	const [otpValue, setOtpValue] = React.useState('')

	const [isModalOpen, setIsModalOpen] = React.useState(false)

	const openModal = () => {
		setIsModalOpen(true)
	}

	const closeModal = () => {
		setIsModalOpen(false)
	}

	return (
		<div className='flex flex-col items-center justify-center gap-10 p-10 font-sans w-scrren'>
			<h2 className='text-2xl font-medium text-text-accent'>Inputs</h2>
			<div className='flex flex-col gap-3'>
				<Input placeholder='Default' />
				<Input placeholder='Default with left Icon' icon={'left'}>
					<Search className='w-5 h-5' />
				</Input>
				<Input placeholder='Default with right Icon' icon={'right'}>
					<Search className='w-5 h-5' />
				</Input>
				<Input placeholder='With Error' error={true} />
				<Input placeholder='Disabled' disabled />
			</div>
			<div className='w-full border-b border-action-normal' />
			<h2 className='text-2xl font-medium text-text-accent'>Buttons</h2>
			<div className='flex flex-wrap items-center justify-center gap-3'>
				<Button variant={'accent'}>
					<Search className='w-5 h-5 mr-2' />
					Button
				</Button>
				<Button variant={'accent'} className='' disabled>
					Button
				</Button>
				<Button variant={'secondary'}>Button</Button>
				<Button variant={'secondary'} disabled>
					Button
				</Button>
				<Button variant={'ghost'}>Button</Button>
				<Button variant={'ghost'} disabled>
					Button
				</Button>
				<Button variant={'outline'}>Button</Button>
				<Button variant={'outline'} disabled>
					Button
				</Button>
				<Button variant={'chip'}>Chip</Button>
				<Button variant={'link'}>Link</Button>
			</div>
			<div className='w-full border-b border-action-normal' />
			<h2 className='text-2xl font-medium text-text-accent'>Input OTP:</h2>
			<InputOTP pattern={REGEXP_ONLY_DIGITS} maxLength={9} value={otpValue} onChange={value => setOtpValue(value)}>
				<InputOTPGroup>
					<InputOTPSlot index={0} />
					<InputOTPSlot index={1} />
					<InputOTPSlot index={2} />
					<InputOTPSlot index={4} />
					<InputOTPSlot index={5} />
					<InputOTPSlot index={6} />
					<InputOTPSlot index={7} />
					<InputOTPSlot index={8} />
				</InputOTPGroup>
			</InputOTP>
			<div className='w-full border-b border-action-normal' />
			<h2 className='text-2xl font-medium text-text-accent'>Modal with Header</h2>
			<Button onClick={openModal}>Open Modal</Button>
			<Modal isOpen={isModalOpen} onClose={closeModal}>
				<Flow />
			</Modal>
			<div className='w-full border-b border-action-normal' />
			<h2 className='text-2xl font-medium text-text-accent'>Service Card</h2>
			<ServiceCard
				service={{
					available_end_at: '1',
					available_start_at: '1',
					category_id: 1,
					category: { id: 1, title: 'Категория услуги' },
					comment: '123',
					description: '13',
					id: 1,
					price: 11990,
					quantity: 14,
					title: 'Великолепная и неповторимая услуга, позволяющая обрести определенные и очень ценные навыки в том, что действительно важно',
					unit: { title: 'RUB', id: 1 },
				}}
			/>
			<div className='w-full border-b border-action-normal' />
			<h2 className='text-2xl font-medium text-text-accent'>User Card</h2>
			<UserCard editAction={() => undefined} />
			<div className='w-full border-b border-action-normal' />
			<h2 className='text-2xl font-medium text-text-accent'>Payment Result with Error</h2>
			<PaymentResult error={true} />
			<div className='w-full border-b border-action-normal' />
			<h2 className='text-2xl font-medium text-text-accent'>Payment Result with Success</h2>
			<PaymentResult error={false} />
			<div className='w-full border-b border-action-normal' />
			<h2 className='text-2xl font-medium text-text-accent'>Main Screen</h2>
			<Main />
			<div className='w-full border-b border-action-normal' />
			<h2 className='text-2xl font-medium text-text-accent'>Auth Screen (Phone Number)</h2>
			<Auth onPhoneSubmit={() => undefined} />
			<div className='w-full border-b border-action-normal' />
			<h2 className='text-2xl font-medium text-text-accent'>Auth Screen (OTP)</h2>
			<OTP onPhoneReset={() => undefined} phoneNumber={formatPhoneNumber('9828726777')} />
			<div className='w-full border-b border-action-normal' />
			<h2 className='text-2xl font-medium text-text-accent'>Payment Screen</h2>
			<Payment />
			<div className='w-full border-b border-action-normal' />
			<h2 className='text-2xl font-medium text-text-accent'>Payment Screen</h2>
			<DropdownMenu>
				<DropdownMenuTrigger>
					<Button>Open Dropdown</Button>
				</DropdownMenuTrigger>
				<DropdownMenuContent>
					<DropdownMenuLabel>Заголовок</DropdownMenuLabel>
					<DropdownMenuSeparator />
					<DropdownMenuItem>Кнопка</DropdownMenuItem>
					<DropdownMenuItem>Кнопка</DropdownMenuItem>
					<DropdownMenuItem>Кнопка</DropdownMenuItem>
					<DropdownMenuItem>Кнопка</DropdownMenuItem>
				</DropdownMenuContent>
			</DropdownMenu>
		</div>
	)
}

export default TestUI
