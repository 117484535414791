export const formatPassportNumber = (value: string) => {
	let valueOnlyDigits = value.replace(/[^0-9]/g, '')
	const match = valueOnlyDigits.match(/(\d{0,4})(\d{0,6})/)
	const matchExceptFirstAndNullish =
		match
			?.filter(m => m.length != 0)
			.slice(1)
			.join(' ') || ''

	return matchExceptFirstAndNullish
}

export const formatDate = (value: string) => {
	let valueOnlyDigits = value.replace(/[^0-9]/g, '')
	const match = valueOnlyDigits.match(/(\d{0,2})(\d{0,2})(\d{0,4})/)
	const matchExceptFirstAndNullish =
		match
			?.filter(m => m.length != 0)
			.slice(1)
			.join('.') || ''
	return matchExceptFirstAndNullish
}

export const convertDate = (value: string) => {
	if (value.length > 10) {
		const date = new Date(value)

		if (isNaN(date.getTime())) {
			throw new Error('Invalid date string')
		}

		const day = date.getDate().toString().padStart(2, '0')
		const month = (date.getMonth() + 1).toString().padStart(2, '0')
		const year = date.getFullYear().toString()

		return `${day}.${month}.${year}`
	} else {
		return value.split('.').reverse().join('-')
	}
}

/** @param plurals [час, часа, часов] */
export const plural = (number: number, plurals: [string, string, string]) => {
	const lastTwoDigits = number % 100
	const lastDigit = number % 10

	if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
		return plurals[2]
	} else if (lastDigit === 1) {
		return plurals[0]
	} else if (lastDigit >= 2 && lastDigit <= 4) {
		return plurals[1]
	} else {
		return plurals[2]
	}
}

export const formatPrice = (value: number | string) => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

export const formatPhoneNumber = (phoneNumber: string) => {
	if (['+', '1', '2', '7', '0'].includes(phoneNumber[0])) phoneNumber = phoneNumber.substring(1)

	let phoneOnlyDigits = phoneNumber.replace(/[^0-9]/g, '')
	const match = phoneOnlyDigits.match(/(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/)
	const matchExceptFirstAndNullish =
		match
			?.filter(m => m.length != 0)
			.slice(1)
			.join('-') || ''
	return matchExceptFirstAndNullish
}

export const formatSeconds = (totalSeconds: number): string => {
	const minutes = Math.floor(totalSeconds / 60)
	const seconds = totalSeconds % 60

	const formattedMinutes = String(minutes).padStart(2, '0')
	const formattedSeconds = String(seconds).padStart(2, '0')

	return `${formattedMinutes}:${formattedSeconds}`
}

export const cleanPhoneNumber = (phoneNumber: string) => {
	let phone = phoneNumber.replace(/[^0-9]/g, '')
	phone = '7' + phone
	return phone
}
