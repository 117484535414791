import { Check, CircleAlert } from 'lucide-react'
import React from 'react'
import { cn } from '../utils/cn'

export interface PaymentResultProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  error: boolean
}

const PaymentResult = React.forwardRef<HTMLDivElement, PaymentResultProps>(({ className, error, ...props }, ref) => {
  return (
    <div
      className={cn('bg-transparent gap-5 flex-col justify-center items-center flex', className)}
      {...props}
      ref={ref}
    >
      {error ? <CircleAlert className='w-12 h-12 text-text-destructive' /> : <Check className='w-12 h-12 text-text-success' />}
      <div className='flex flex-col gap-3 text-center'>
        <span className='text-xl font-medium whitespace-nowrap'>{error ? 'Оплата не прошла' : 'Вы оплатили услугу'}</span>
        <span className=''>{error ? <>Попробуйте еще раз или повторите попытку позже</> : <>Мы отправили чек Вам на почту</>}</span>
      </div>
    </div>
  )
})

export default PaymentResult
