import React from 'react'
import { cn } from '../../utils/cn'
import { Button } from './Button'
//@ts-ignore
import surguPayLogo from 'data-url:../../../resources/surgu-circle.svg'
import { useServiceStore } from '../../store/service'

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

const SurguPayLogo = React.forwardRef<HTMLButtonElement, ButtonProps>(({ className, ...props }, ref) => {
	const serviceState = useServiceStore(state => state.service)
	const setServiceState = useServiceStore(state => state.setService)

	const handleClick = () => {
		if (serviceState) setServiceState(undefined)
	}

	return (
		<Button onClick={handleClick} variant={'chip'} className={cn('text-text-secondary', className)} {...props}>
			<div className='flex flex-row gap-[6px]'>
				<img className='w-5 h-5' src={surguPayLogo} alt={'SurGU Pay'} />
				<span className='font-semibold'>Pay</span>
			</div>
		</Button>
	)
})
export default SurguPayLogo
