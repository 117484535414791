import { X } from 'lucide-react'
import * as React from 'react'
import { cn } from '../../utils/cn'
import { Button } from './Button'
import SurguPayLogo from './SurguPayLogo'

export interface ModalProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
	isOpen: boolean
	onClose: () => void
}

const ModalHeader = ({ onClose }: { onClose: () => void }) => (
	<div className='sticky top-0 z-30 flex items-center w-full h-16 px-6 py-2 border-b bg-surface-default border-action-normal'>
		<div className='flex flex-row justify-between w-full'>
			<SurguPayLogo />
			<Button variant={'link'} className='p-0 hover:text-text-secondary text-text-tertiary' onClick={onClose}>
				<X className='w-6 h-6 y' />
			</Button>
		</div>
	</div>
)

const Modal = React.forwardRef<HTMLDivElement, ModalProps>(({ className, children, isOpen, onClose, ...props }, ref) => {
	return (
		<>
			{isOpen && (
				<div
					className={cn('relative z-10', isOpen ? 'animate-fade animate-once animate-duration-200 animate-ease-out' : 'animate-none')}
					role='dialog'
					aria-modal='true'
					ref={ref}
				>
					<div className='fixed inset-0 transition-opacity bg-opacity-75 bg-[#271D1DCC]/80' />
					<div className='fixed inset-0 z-10 scrollbar-hide'>
						<div className='flex items-center justify-center w-full min-h-full '>
							<div
								className={cn(
									'relative text-left scrollbar-hide transition-all w-full h-screen overflow-y-auto shadow-[0px_4px_18px_0px_rgba(220,218,250,0.3)] bg-surface-neutral',
									'md:h-auto md:max-h-[70vh] md:w-[560px] md:rounded ',
									className
								)}
								{...props}
							>
								<ModalHeader onClose={onClose} />
								<div className='p-6 '>{children}</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
})

export default Modal
