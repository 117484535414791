import axios, { AxiosError } from 'axios'

const axi = axios.create({
	baseURL: 'https://pay.surgu.ru/api',
	headers: {
		'Content-Type': 'application/json',
	},
	timeout: 10000,
})

axi.interceptors.request.use(
	config => {
		const token = localStorage.getItem('token')
		if (token) {
			config.headers.Authorization = `Bearer ${token}`
		}
		return config
	},
	(error: AxiosError) => {
		console.log('Axios Error: ', error)
		return Promise.reject(error)
	}
)

axi.interceptors.response.use(
	response => {
		if (typeof response === 'string') response = JSON.parse(response)
		return response
	},
	(error: AxiosError) => {
		console.log('Axios Error: ', error)
		return Promise.reject(error)
	}
)

export default axi
