import React from 'react'
import { cn } from '../utils/cn'

export interface LayoutProps extends React.HtmlHTMLAttributes<HTMLDivElement> {}

const PageLayout = React.forwardRef<HTMLDivElement, LayoutProps>(({ className, children, ...props }, ref) => {
	return (
		<div className={cn('flex flex-col items-center justify-center w-full min-h-screen gap-10 p-4', className)} {...props} ref={ref}>
			{children}
		</div>
	)
})

export default PageLayout
