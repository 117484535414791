import * as React from 'react'

import { VariantProps, cva } from 'class-variance-authority'
import { cn } from '../../utils/cn'

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement>, VariantProps<typeof inputVariants> {}
const inputVariants = cva(
	'flex slashed-zero w-full rounded border bg-input-normal placeholder:text-text-tertiary text-text-primary shadow-sm px-3 focus-visible:outline-none py-2.5 disabled:cursor-not-allowed placeholder:disabled:text-text-quaternary ring-2 ring-transparent disabled:ring-[rgba(0,119,255,0.20)]',
	{
		variants: {
			error: {
				false: 'border-action-normal focus-visible:border-action-focus-light focus-visible:ring-[rgba(32,66,185,0.20)]',
				true: 'focus-visible:border-action-focus-destructive-light border-action-destructive focus-visible:ring-[rgba(255,178,178,0.60)]',
			},
			icon: {
				left: 'pl-11',
				right: 'pr-11',
			},
		},
		defaultVariants: {
			error: false,
		},
	}
)

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, error, type, icon, children, ...props }, ref) => {
	return (
		<div className='relative flex w-full'>
			<span
				className={cn(
					'absolute inset-y-0 flex items-center text-text-tertiary focus-visible:text-text-primary',
					icon === 'left' ? 'left-0 pl-3' : 'right-0 pr-3'
				)}
			>
				{icon && children}
			</span>
			<input type={type} className={cn(inputVariants({ error, icon, className }))} ref={ref} {...props} />
		</div>
	)
})
Input.displayName = 'Input'

export { Input }
