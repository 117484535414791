import * as React from 'react'

import { Slot } from '@radix-ui/react-slot'
import { VariantProps, cva } from 'class-variance-authority'
import { cn } from '../../utils/cn'

export interface InputProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {}
const buttonVariants = cva(
	'px-3 py-2.5 bg-transparent justify-center gap-1 items-center inline-flex rounded transition-all focus-visible:outline-none disabled:pointer-events-none disabled:opacity-20',
	{
		variants: {
			variant: {
				accent: 'bg-button-accent text-text-inverted hover:bg-button-accent-hover',
				secondary: 'bg-button-secondary text-text-accent hover:bg-button-tertiary-hover  border-border-alpha border',
				ghost: 'text-text-primary hover:bg-button-ghost-hover',
				outline: 'text-text-accent border border-action-focus-light',
				link: 'text-text-accent underline-offset-4 hover:underline focus:underline p-0',
				chip: 'rounded-[20px] border text-text-tertiary border-action-normal bg-surface-default font-medium text-sm px-2 py-1 hover:bg-button-secondary-hover',
			},
			icon: {
				left: 'pl-10',
				right: 'pr-10',
			},
		},
		defaultVariants: {
			variant: 'accent',
		},
	}
)

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
	asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({ className, variant, asChild = false, ...props }, ref) => {
	const Comp = asChild ? Slot : 'button'
	return <Comp className={cn(buttonVariants({ variant, className }))} ref={ref} {...props} />
})

Button.displayName = 'Button'

export { Button, buttonVariants }
