import { useState } from 'react'
import { Button } from './Button'
import oferta from 'data-url:../../../resources/oferta-surgupay.pdf'
import { X } from 'lucide-react'

const Oferta = ({ onClose }: { onClose: () => void }) => {
  return (
    <div
      className='absolute inset-0 p-3 lg:p-20 bg-text-tertiary/50'
      onClick={onClose}
    >
      <div className='relative flex flex-col items-end gap-4 size-full'>
        <X className='transition-opacity size-10 text-action-normal opacity-70 hover:opacity-100 hover:cursor-pointer' />
        <iframe
          className='size-full'
          src={oferta}
        />
      </div>
    </div>
  )
}

const Terms = () => {
  const [isOfertaOpen, setIsOfertaOpen] = useState(false)

  return (
    <div className='flex flex-col gap-2'>
      {isOfertaOpen && <Oferta onClose={() => setIsOfertaOpen(false)} />}
      <Button
        variant={'link'}
        onClick={() => setIsOfertaOpen(true)}
        rel='noreferrer'
        className='flex justify-center w-full text-sm text-text-tertiary hover:text-text-primary'
      >
        Публичная оферта
      </Button>
      <div className='flex flex-col text-xs text-center text-text-tertiary'>
        <span>БУ ВО «Сургутский государственный университет»</span>
        <span>628412, Ханты- Мансийский автономный округ-Югра, г. Сургут, пр. Ленина, 1</span>
        <span>ОКПО 27387694 / ОКТМО 71876000001 / КБК 00000000000000000130</span>
        <span>ИНН 8602200001 / КПП 860201001 / БИК 007162163</span>
        <span>Телефоны: (3462) 76-29-00, 76-29-29</span>
        <span>Электронная почта: dpo@surgu.ru </span>
      </div>
    </div>
  )
}

export default Terms
