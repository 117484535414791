import { useMutation } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { useState } from 'react'
import { SmsData, sendSms } from '../api/auth'
import { Button } from '../components/ui/Button'
import { Input } from '../components/ui/Input'
import { cn } from '../utils/cn'
import { cleanPhoneNumber, formatPhoneNumber } from '../utils/format'

type AuthProps = {
	onPhoneSubmit: (str: string) => void
}

const Phone = ({ onPhoneSubmit }: AuthProps) => {
	const smsMutation = useMutation({
		mutationFn: sendSms,
		onSuccess: (data: AxiosResponse<SmsData>) => {
			console.log(data)
			localStorage.setItem('otp-jwt', data.data.token)
			onPhoneSubmit(phoneNumber)
		},
	})

	const [phoneNumber, setPhoneNumber] = useState('')

	const onSubmit = () => smsMutation.mutate(cleanPhoneNumber(phoneNumber))

	const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') onSubmit()
	}

	return (
		<div className='flex flex-col justify-center w-full gap-6'>
			<div className='flex flex-col justify-center w-full gap-3'>
				<h1 className='text-xl font-medium whitespace-nowrap'>Номер телефона</h1>
				<p className='text-text-secondary'>Введите номер телефона и мы отправим СМС с кодом подтверждения.</p>
			</div>
			<Input
				autoFocus
				disabled={smsMutation.isPending}
				placeholder='000 000 00 00'
				className='pl-9'
				value={phoneNumber}
				onChange={e => setPhoneNumber(formatPhoneNumber(e.target.value))}
				onKeyDown={handleEnter}
				icon={'left'}
			>
				<span className='text-text-primary'>+7</span>
			</Input>
			{smsMutation.error && <p className='-mt-3 text-sm text-text-destructive'>Возникла ошибка, попробуйте позже или обратитесь в поддержку.</p>}
			<Button
				disabled={smsMutation.isPending}
				className={cn(smsMutation.isPending && 'animate-pulse animate-infinite hover:cursor-wait')}
				variant={'accent'}
				onClick={onSubmit}
			>
				Получить код
			</Button>
			<p className='text-sm text-text-tertiary' id='parag'>
				При входе и регистрации вы соглашаетесь на обработку персональных данных, согласно Федеральному закону от 27 июля 2006 г. № 152-ФЗ о персональных
				данных.
			</p>
		</div>
	)
}

export default Phone
