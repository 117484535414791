import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { createRoot } from 'react-dom/client'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { App } from './App'
import PageLayout from './components/PageLayout'
import PaymentResult from './components/PaymentResult'
import SurguLogo from './components/ui/SurguLogo'
import TestUI from './pages/TestUI'
// @ts-ignore
import cssText from 'bundle-text:./index.css'

// Clear the existing HTML content
// document.body.innerHTML = '<div id="app"></div>'

// inject <style> tag
let style = document.createElement('style')
style.textContent = cssText
document.head.appendChild(style)

const queryClient = new QueryClient()
declare module '@tanstack/react-query' {
  interface Register {
    defaultError: AxiosError
  }
}

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <PageLayout>
        <App />
      </PageLayout>
    ),
  },
  {
    path: '/success',
    element: (
      <PageLayout>
        <SurguLogo
          className='absolute top-4'
          onClick={() => window.open('https://pay.surgu.ru/', '_self')}
        />
        <PaymentResult error={false} />
      </PageLayout>
    ),
  },
  {
    path: '/error',
    element: (
      <PageLayout>
        <SurguLogo
          className='absolute top-4'
          onClick={() => window.open('https://pay.surgu.ru/', '_self')}
        />
        <PaymentResult error={true} />
      </PageLayout>
    ),
  },
  {
    path: '/test',
    element: (
      <PageLayout>
        <TestUI />
      </PageLayout>
    ),
  },
])

const rootElement = document.createElement('div')
document.body.appendChild(rootElement)
const root = createRoot(rootElement)
root.render(
  <QueryClientProvider client={queryClient}>
    <RouterProvider router={router} />
  </QueryClientProvider>
)
