'use client'

import { OTPInput, OTPInputContext } from 'input-otp'
import * as React from 'react'

import { cn } from '../../utils/cn'

const InputOTP = React.forwardRef<
  React.ElementRef<typeof OTPInput>,
  React.ComponentPropsWithoutRef<typeof OTPInput> & { containerClassName: string; className: string }
>(({ className, containerClassName, ...props }, ref) => (
  <OTPInput
    ref={ref}
    containerClassName={cn('flex items-center w-full justify-center gap-2 has-[:disabled]:opacity-50', containerClassName)}
    className={cn('disabled:cursor-not-allowed', className)}
    {...props}
  />
))
InputOTP.displayName = 'InputOTP'

const InputOTPGroup = React.forwardRef<React.ElementRef<'div'>, React.ComponentPropsWithoutRef<'div'>>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn('flex flex-row items-center gap-4 w-full justify-center', className)} {...props} />
))
InputOTPGroup.displayName = 'InputOTPGroup'

const InputOTPSlot = React.forwardRef<
  React.ElementRef<'div'>,
  React.ComponentPropsWithoutRef<'div'> & { className: string } & { index: number; error?: boolean }
>(({ index, className, error, ...props }, ref) => {
  const inputOTPContext = React.useContext(OTPInputContext)
  const { char, hasFakeCaret, isActive } = inputOTPContext.slots[index]

  return (
    <div
      autoFocus={index === 0}
      ref={ref}
      className={cn(
        'relative slashed-zero bg-surface-default flex w-full py-2.5 px-4 items-center justify-center border border-action-normal text-xl transition-all rounded',
        isActive && 'z-20 ring-2 ring-offset-action-focus-light',
        !char && 'text-text-tertiary',
        className,
        isActive && error && 'ring-2 border-action-focus-destructive-light  ring-[rgba(255,178,178,0.60)]',
        error && '  border-action-destructive'
      )}
      {...props}
    >
      {char ? char : 0}
      {hasFakeCaret && (
        <div className='absolute inset-0 flex items-center justify-center pointer-events-none'>
          <div className='w-px h-4 duration-1000 animate-caret-blink bg-foreground' />
        </div>
      )}
    </div>
  )
})
InputOTPSlot.displayName = 'InputOTPSlot'

export { InputOTP, InputOTPGroup, InputOTPSlot }
