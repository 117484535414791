import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { getUser } from '../api/user'
import ServicesSkeleton from '../components/skeleton/ServicesSkeleton'
import { useServiceStore } from '../store/service'
import { useUserStore } from '../store/user'
import AuthWithPhone from './AuthWithPhone'
import Payment from './Payment'
import Services from './Services'

export interface FlowProps extends React.HtmlHTMLAttributes<HTMLDivElement> {}

const Flow = React.forwardRef<HTMLDivElement, FlowProps>(({ className, children, ...props }, ref) => {
	const setUserState = useUserStore(state => state.setUser)
	const userState = useUserStore(state => state.user)
	const serviceState = useServiceStore(state => state.service)

	const [isModalOpen, setIsModalOpen] = React.useState(false)

	const containerClassname = 'flex w-full flex-col items-center justify-center font-sans max-w-[520px] mx-auto bg-surface-neutral'

	const userFetch = useQuery({
		queryKey: ['user'],
		queryFn: getUser,
		refetchOnWindowFocus: false,
		retry: !!localStorage.getItem('token'),
		enabled: !userState,
	})

	if (userFetch.isPending) {
		return (
			<div ref={ref} className={containerClassname}>
				<ServicesSkeleton />
			</div>
		)
	} else if (userFetch.isError && userFetch.error.response?.status != 401) {
		return (
			<div ref={ref} className={containerClassname}>
				<span>Server Error</span>
			</div>
		)
	}

	if (userFetch.isSuccess && !userState && localStorage.getItem('token')) setUserState(userFetch.data.data)

	return (
		<div ref={ref} className={containerClassname}>
			{!serviceState && <Services />}
			{!userState && serviceState && <AuthWithPhone />}
			{serviceState && userState && <Payment />}
		</div>
	)
})

export default Flow
