import React from 'react'
import OTP from './OTP'
import Phone from './Phone'

const AuthWithPhone = () => {
	const [phoneNumber, setPhoneNumber] = React.useState<string | undefined>(undefined)

	if (phoneNumber) {
		return <OTP phoneNumber={phoneNumber} onPhoneReset={() => setPhoneNumber(undefined)} />
	} else {
		return <Phone onPhoneSubmit={phone => setPhoneNumber(phone)} />
	}
}

export default AuthWithPhone
