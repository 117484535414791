import SurguLogo from './components/ui/SurguLogo'
import Terms from './components/ui/Terms'
import Flow from './pages/Flow'

export const App = () => {
  return (
    <>
      <SurguLogo />
      <Flow />
      <Terms />
    </>
  )
}
