export function filterNullValues(obj: { [key: string]: any }): { [key: string]: any } {
	return Object.entries(obj)
		.filter(([_, value]) => value !== null)
		.reduce((acc, [key, value]) => {
			if (typeof value === 'object') {
				acc[key] = filterNullValues(value)
			} else {
				acc[key] = value
			}
			return acc
		}, {} as { [key: string]: any })
}
