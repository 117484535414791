import { Ellipsis, LogOut, Pencil } from 'lucide-react'
import React from 'react'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../components/ui/Dropdown'
import { useUserStore } from '../store/user'
import { cn } from '../utils/cn'
import { formatPhoneNumber } from '../utils/format'
import { Button } from './ui/Button'

export interface UserCardProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  editAction: () => void
}

const UserCard = React.forwardRef<HTMLDivElement, UserCardProps>(({ className, editAction, ...props }, ref) => {
  const userState = useUserStore((state) => state.user)
  const setUserState = useUserStore((state) => state.setUser)

  if (!userState) return <div className='w-full flex h-[94px] rounded bg-skeleton animate-pulse animate-infinite' />
  return (
    <div
      className={cn(
        'inline-flex justify-between gap-8 p-3 border rounded bg-surface-default border-action-normal text-start w-full',
        className
      )}
      ref={ref}
      {...props}
    >
      <div className='flex flex-col gap-1'>
        <span className='font-normal tracking-tight'>
          {userState.last_name} {userState.first_name} {userState.patronymic && userState.patronymic}
        </span>
        <span className='text-sm slashed-zero text-text-secondary'>
          +7 {formatPhoneNumber(userState.phone)}, {userState.email}
        </span>
        <span className='text-sm text-text-tertiary slashed-zero'>
          {userState.passport_number}, {userState.passport_date}
        </span>
      </div>
      <div>
        <DropdownMenu>
          <DropdownMenuTrigger>
            <Button
              className='w-11 h-11 hover:text-text-secondary text-text-tertiary'
              variant={'secondary'}
            >
              <Ellipsis className='w-full h-full' />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {/* <DropdownMenuLabel>Настройки профиля</DropdownMenuLabel>
				<DropdownMenuSeparator /> */}
            <DropdownMenuItem onClick={editAction}>
              <Pencil className='w-4 h-4 mr-2' /> <span>Редактировать</span>
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={() => {
                setUserState(undefined)
                localStorage.removeItem('token')
              }}
            >
              <LogOut className='w-4 h-4 mr-2' /> <span>Сменить профиль</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  )
})

export default UserCard
