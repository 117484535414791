import { create } from 'zustand'
import { Service } from '../api/service'

type ServiceState = Service | undefined

type ServiceStore = {
	service: ServiceState
	setService: (service: ServiceState) => void
}

export const useServiceStore = create<ServiceStore>(set => ({
	service: undefined,
	setService: service => set(() => ({ service })),
}))
