import React, { useEffect, useState } from 'react'
import { Service } from '../api/service'
import { cn } from '../utils/cn'
import { formatPrice, plural } from '../utils/format'

export interface ServiceCardProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  service: Service
}

const ServiceCard = React.forwardRef<HTMLButtonElement, ServiceCardProps>(({ service, className, ...props }, ref) => {
  const { title, description, quantity, unit, price } = service

  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false)
  const [formattedDescription, setFormattedDescription] = useState(description)
  const isDescriptionLong = description ? description.split(' ').length > 14 : false
  useEffect(() => {
    if (description && isDescriptionLong) setFormattedDescription(description.split(' ').slice(0, 14).join(' ') + '…')
    else setFormattedDescription(description)
  }, [description, isDescriptionLong])

  const unitTitle =
    unit?.title === 'час'
      ? plural(quantity || 1, ['час', 'часа', 'часов'])
      : unit?.title === 'минута'
      ? plural(quantity || 1, ['минута', 'минуты', 'минут'])
      : unit?.title === 'месяц'
      ? plural(quantity || 1, ['месяц', 'месяца', 'месяцев'])
      : unit?.title === 'год'
      ? plural(quantity || 1, ['год', 'года', 'лет'])
      : unit?.title

  const formattedTime = `${quantity} ${unitTitle}`

  return (
    <button
      className={cn(
        'flex flex-col gap-2 disabled:pointer-events-none w-full disabled:opacity-20 p-3 transition-all border rounded hover:bg-button-secondary-hover bg-surface-default border-action-normal ',
        className
      )}
      ref={ref}
      {...props}
    >
      <div className='inline-flex justify-between w-full gap-8 text-start'>
        <div className='flex flex-col gap-1'>
          <h3 className='text-base tracking-tight'>{title}</h3>
          <span className='text-sm text-text-secondary'>{formattedTime}</span>
        </div>
        <span className='text-base whitespace-nowrap text-text-accent'>{formatPrice(price)} ₽</span>
      </div>
      {description && description.length && (
        <p className='text-sm leading-snug text-start '>
          <span className={cn('text-text-tertiary')}>{formattedDescription}</span>
          &nbsp;
          {isDescriptionLong && !isDescriptionExpanded && (
            <span
              onClick={(e) => {
                e.stopPropagation()
                setFormattedDescription(description)
                setIsDescriptionExpanded(!isDescriptionExpanded)
              }}
              className='transition-all text-button-accent hover:underline hover:text-button-accent-hover'
            >
              Показать полностью
            </span>
          )}
        </p>
      )}
    </button>
  )
})

ServiceCard.displayName = 'ServiceCard'

export default ServiceCard
