import { create } from 'zustand'
import { User } from '../api/user'

type UserState = User | undefined

type UserStore = {
	user: UserState
	setUser: (user: UserState) => void
}

export const useUserStore = create<UserStore>(set => ({
	user: undefined,
	setUser: user => {
		console.log('set user to', user)
		return set(() => ({ user }))
	},
}))
