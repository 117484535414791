import { AxiosResponse } from 'axios'
import axi from './axios'

export type SmsData = {
	status: 'success' | 'error'
	message: string | undefined
	token: string
}

export const sendSms = async (phoneNumber: string) => {
	const data: AxiosResponse<SmsData> = await axi.post('/sms', {
		phone: phoneNumber,
	})

	return data
}

export type AuthData = {
	token: string
}
export type AuthRequest = {
	token: string
	code: string
}

export const authenticate = async ({ token, code }: AuthRequest) => {
	const data: AxiosResponse<AuthData> = await axi.post('/auth', {
		token,
		code,
	})
	return data
}
