import { zodResolver } from '@hookform/resolvers/zod'
import React, { useState } from 'react'
import { Control, Controller, FieldError, UseFormRegister, useForm } from 'react-hook-form'
import { z } from 'zod'
import { User } from '../api/user'
import { useUserStore } from '../store/user'
import { formatDate, formatPassportNumber } from '../utils/format'
import { filterNullValues } from '../utils/object'
import { Button } from './ui/Button'
import { Input } from './ui/Input'

export type UserFormData = z.infer<typeof formSchema>

type FormFieldProps = {
  type: string
  placeholder: string
  name: keyof z.infer<typeof formSchema>
  register: UseFormRegister<UserFormData>
  error: FieldError | undefined
}

// Custom Controllable Field
const BirthDateInput = ({ control, error, defaultValue }: { control: Control<any>; error: FieldError | undefined; defaultValue?: string }) => {
  const [birthDate, setBirthDate] = useState(formatDate(defaultValue || ''))
  return (
    <Controller
      control={control}
      name='birthday'
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <div className='flex flex-col w-full gap-2'>
          <label htmlFor='birthday' className='flex gap-2 text-sm font-medium'>
            Дата рождения
          </label>
          <Input
            placeholder='дд.мм.гггг'
            id='birthday'
            onChange={(e) => {
              setBirthDate(formatDate(e.currentTarget.value))
              onChange(formatDate(e.currentTarget.value))
            }} // send value to hook form
            onBlur={onBlur} // notify when input is touched/blur
            value={birthDate}
            error={error && true}
            ref={ref}
          />
          {/* {error && <span className='text-sm text-text-destructive'>{error.message}</span>} */}
        </div>
      )}
    />
  )
}

// Custom Controllable Field
const PassportDateInput = ({ control, error, defaultValue }: { control: Control<any>; error: FieldError | undefined; defaultValue?: string }) => {
  const [passportDate, setPassportDate] = useState(formatDate(defaultValue || ''))
  return (
    <Controller
      control={control}
      name='passport_date'
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <div className='flex flex-col w-full gap-2'>
          <label htmlFor='passport_date' className='flex gap-2 text-sm font-medium'>
            Дата выдачи
          </label>
          <Input
            placeholder='дд.мм.гггг'
            id='passport_date'
            onChange={(e) => {
              setPassportDate(formatDate(e.currentTarget.value))
              onChange(formatDate(e.currentTarget.value))
            }} // send value to hook form
            onBlur={onBlur} // notify when input is touched/blur
            value={passportDate}
            error={error && true}
            ref={ref}
          />
          {/* {error && <span className='text-sm text-text-destructive'>{error.message}</span>} */}
        </div>
      )}
    />
  )
}

// Custom Controllable Field
const PassportNumberInput = ({ control, error, defaultValue }: { control: Control<any>; error: FieldError | undefined; defaultValue?: string }) => {
  const [passportNumber, setPassportNumber] = useState(formatPassportNumber(defaultValue || ''))
  return (
    <Controller
      control={control}
      name='passport_number'
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <div className='flex flex-col w-full gap-2'>
          <label htmlFor='passport_number' className='flex gap-2 text-sm font-medium'>
            Серия и номер паспорта
          </label>
          <Input
            placeholder='1234 567890'
            id='passport_number'
            onChange={(e) => {
              setPassportNumber(formatPassportNumber(e.currentTarget.value))
              onChange(formatPassportNumber(e.currentTarget.value))
            }} // send value to hook form
            onBlur={onBlur} // notify when input is touched/blur
            value={passportNumber}
            error={error && true}
            ref={ref}
          />
          {/* {error && <span className='text-sm text-text-destructive'>{error.message}</span>} */}
        </div>
      )}
    />
  )
}

export const FormField: React.FC<FormFieldProps & { labelText: string }> = ({ type, placeholder, name, register, error, labelText }) => (
  <div className='flex flex-col w-full gap-2'>
    <label htmlFor={name} className='flex gap-2 text-sm font-medium'>
      {labelText} {name === 'patronymic' && <span className='font-normal text-text-tertiary'>(при наличии)</span>}
    </label>
    <Input id={name} error={error && true} type={type} placeholder={placeholder} {...register(name)} />

    {/* {error && <span className='text-sm text-text-destructive'>{error.message}</span>} */}
  </div>
)

const invalid_type_error = 'Поле заполнено неверно'
const required_error = 'Это поле обязательно для заполнения'

const formSchema = z.object({
  last_name: z.string({ invalid_type_error, required_error }).min(1, required_error).max(256),
  first_name: z.string({ invalid_type_error, required_error }).min(1, required_error).max(256),
  patronymic: z.string({ invalid_type_error, required_error }).max(256).nullable(),
  passport_number: z
    .string({ invalid_type_error, required_error })
    .regex(/^\d{4}\s\d{6}$/, 'Данные указаны неверно')
    .min(1, required_error),
  passport_date: z
    .string({ invalid_type_error, required_error })
    .regex(/^\d{2}\.\d{2}\.\d{4}$/, 'Дата указана неверно')
    .min(1, required_error),
  birthday: z
    .string({ invalid_type_error, required_error })
    .regex(/^\d{2}\.\d{2}\.\d{4}$/, 'Дата указана неверно')
    .min(1, required_error),
  email: z.string({ invalid_type_error, required_error }).email('Почта указана неверно').min(1, required_error),
})

type UserFormProps = {
  onSubmit: (data: UserFormData) => void
  defaultValues?: User
}

const UserForm = ({ onSubmit }: UserFormProps) => {
  const userState = useUserStore((state) => state.user)
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<UserFormData>({
    resolver: zodResolver(formSchema),
    ...(userState ? { defaultValues: filterNullValues(userState) } : null),
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col w-full gap-5'>
      <div className='flex flex-row gap-3'>
        <FormField type='text' placeholder='Иванов' name='last_name' register={register} error={errors.last_name} labelText='Фамилия' />
        <FormField type='text' placeholder='Иван' name='first_name' register={register} error={errors.first_name} labelText='Имя' />
      </div>
      <div className='flex flex-row items-end w-full gap-3'>
        <FormField type='text' placeholder='Иванович' name='patronymic' register={register} error={errors.patronymic} labelText='Отчество' />
        <BirthDateInput defaultValue={userState?.birthday || ''} control={control} error={errors.birthday} />
      </div>
      <div className='flex flex-row items-end w-full gap-3'>
        <PassportNumberInput defaultValue={userState?.passport_number || ''} control={control} error={errors.passport_number} />
        <PassportDateInput defaultValue={userState?.passport_date || ''} control={control} error={errors.passport_date} />
      </div>
      <FormField type='email' placeholder='example@surctf.ru' name='email' register={register} error={errors.email} labelText='Адрес электронной почты' />
      <span className='-mt-4 text-sm text-text-tertiary'>Отправим чек на почту</span>
      <div className='flex flex-col w-full gap-6'>
        <Button variant={'accent'} type='submit'>
          Сохранить данные
        </Button>
      </div>
    </form>
  )
}

export default UserForm
