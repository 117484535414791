import { useQuery } from '@tanstack/react-query'
import { Search } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'
import { getServices, Services } from '../api/service'
import ServiceCard from '../components/ServiceCard'
import ServicesSkeleton from '../components/skeleton/ServicesSkeleton'
import { Button } from '../components/ui/Button'
import { Input } from '../components/ui/Input'
import { useServiceStore } from '../store/service'
import { cn } from '../utils/cn'

const Main = () => {
  const setServicesState = useServiceStore((state) => state.setService)

  const { data: services } = useQuery({
    queryKey: ['services'],
    queryFn: getServices,
    refetchOnWindowFocus: false,
    staleTime: 60 * 1000,
  })

  const [selectedCategories, setSelectedCategories] = useState<string[]>([])
  const [searchQuery, setSearchQuery] = useState('')
  const [debouncedSearchQuery] = useDebounce(searchQuery, 100)

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  const [filteredServices, setFilteredServices] = useState<Services>()
  const [categories, setCategories] = useState<Array<string>>([])

  useEffect(() => {
    if (services?.data) {
      setFilteredServices([
        ...services.data.filter(
          (service) =>
            service.title.toLowerCase().includes(debouncedSearchQuery.toLowerCase()) &&
            (selectedCategories.length ? selectedCategories.includes(service.category.title) : true)
        ),
      ])

      setCategories(
        Object.keys(
          services.data.reduce((acc, service) => {
            acc[service.category.title] = true
            return acc
          }, {} as Record<string, boolean>)
        ) as string[]
      )
    }
  }, [services, selectedCategories, debouncedSearchQuery])

  if (!filteredServices || !categories) {
    return <ServicesSkeleton />
  }

  return (
    <div className='flex flex-col justify-center w-full gap-6'>
      <div className='flex flex-col justify-center gap-3'>
        <Input onChange={handleSearch} placeholder='Поиск услуги' icon={'left'}>
          <Search className='w-5 h-5' />
        </Input>
        <div className='flex flex-wrap gap-2'>
          {categories.map((category) => {
            return (
              <Button
                key={category}
                onClick={() =>
                  selectedCategories.includes(category)
                    ? setSelectedCategories([...selectedCategories].filter((c) => c != category))
                    : setSelectedCategories([...selectedCategories, category])
                }
                className={cn(selectedCategories.includes(category) && 'text-text-accent')}
                variant={'chip'}
              >
                {category}
              </Button>
            )
          })}
        </div>
      </div>
      <div className='flex flex-col justify-center gap-3'>
        {filteredServices.map((service) => {
          return <ServiceCard onClick={() => setServicesState(service)} service={service} key={service.id} />
        })}
      </div>
    </div>
  )
}

export default Main
