'use client'
import React, { useEffect, useState } from 'react'

import { useMutation } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { REGEXP_ONLY_DIGITS } from 'input-otp'
import { AuthData, SmsData, authenticate, sendSms } from '../api/auth'
import { getUser } from '../api/user'
import { Button } from '../components/ui/Button'
import { InputOTP, InputOTPGroup, InputOTPSlot } from '../components/ui/InputOTP'
import { useUserStore } from '../store/user'
import { cn } from '../utils/cn'
import { formatSeconds } from '../utils/format'

const SMS_RESEND_TIME = 59

type OTPProps = {
	phoneNumber: string
	onPhoneReset: () => void
}
const OTP = ({ phoneNumber, onPhoneReset }: OTPProps) => {
	const setUserState = useUserStore(state => state.setUser)

	const smsMutation = useMutation({
		mutationFn: sendSms,
		onSuccess: (data: AxiosResponse<SmsData>) => {
			console.log(data)
			localStorage.setItem('otp-jwt', data.data.token)
		},
	})

	const [otpCode, setOtpCode] = React.useState('')
	const [timeLeft, setTimeLeft] = useState(SMS_RESEND_TIME)
	const [intervalId, setIntervalId] = useState<NodeJS.Timeout>()

	const resetTimer = () => {
		intervalId && clearInterval(intervalId)
		setTimeLeft(SMS_RESEND_TIME)
		const id = setInterval(() => {
			setTimeLeft(prevTime => {
				if (prevTime === 0) {
					clearInterval(id)
					return 0
				}
				return prevTime - 1
			})
		}, 1000)
		setIntervalId(id)
	}

	useEffect(() => {
		return resetTimer()
	}, [])

	const authMutation = useMutation({
		mutationFn: authenticate,
		onSuccess: (data: AxiosResponse<AuthData>) => {
			localStorage.setItem('token', data.data.token)
			localStorage.removeItem('otp-jwt')
			getUser().then(data => setUserState(data.data))
		},
	})

	useEffect(() => {
		if (otpCode.length === 4) {
			authMutation.mutate({
				token: localStorage.getItem('otp-jwt')!,
				code: otpCode,
			})
		}
	}, [otpCode])

	return (
		<div className='flex flex-col items-start justify-center w-full gap-10'>
			<div className='flex flex-col items-start w-full gap-3'>
				<h1 className='text-xl font-medium whitespace-nowrap'>Введите код</h1>
				<p className='text-text-secondary leading-[1.4rem]'>
					SMS-сообщение отправлено на номер <br /> <span className='text-text-primary'>+7 {phoneNumber ? phoneNumber : null}</span>{' '}
				</p>
				<Button onClick={onPhoneReset} className='text-sm' variant={'link'}>
					Изменить номер телефона
				</Button>
			</div>
			<div className='flex flex-col items-start w-full gap-3'>
				<InputOTP autoFocus pattern={REGEXP_ONLY_DIGITS} maxLength={4} value={otpCode} onChange={value => setOtpCode(value)}>
					<InputOTPGroup className={cn(authMutation.isPending && 'animate-pulse animate-infinite hover:cursor-wait')}>
						<InputOTPSlot error={authMutation.isError} aria-disabled={authMutation.isPending} index={0} />
						<InputOTPSlot error={authMutation.isError} aria-disabled={authMutation.isPending} index={1} />
						<InputOTPSlot error={authMutation.isError} aria-disabled={authMutation.isPending} index={2} />
						<InputOTPSlot error={authMutation.isError} aria-disabled={authMutation.isPending} index={3} />
					</InputOTPGroup>
				</InputOTP>

				<div className='flex flex-col items-start gap-1 text-sm'>
					{authMutation.error && <span className='text-text-destructive'>Вы ввели неправильный код</span>}
					{smsMutation.error && <span className='text-text-destructive'>{smsMutation.error.message}</span>}
					{timeLeft > 0 ? (
						<span className='text-text-secondary'>SMS придёт в течение {formatSeconds(timeLeft)} </span>
					) : (
						<span className='text-text-secondary'>
							Не получили код?{' '}
							<Button
								variant={'link'}
								onClick={() => {
									resetTimer()
									smsMutation.mutate(phoneNumber)
								}}
							>
								Отправить SMS повторно
							</Button>
						</span>
					)}
				</div>
			</div>
		</div>
	)
}

export default OTP
